import React, { useState, useEffect, useContext } from "react"
import Carousel from "react-multi-carousel"
import { database } from "./firebaseConfig"
import { ref, onValue } from "firebase/database"
import axios from 'axios'
import ReactPlayer from 'react-player'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-multi-carousel/lib/styles.css"

import israel from './img/israel01.png'
import done from './img/done.png'

import GlobalContext from "./context/GlobalContext";
import Us from "./Us"

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const videosRef = ref(database, 'videos/')
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

function Main() {
  const { logged, customerInfo, setShowPayment, stripeCostumer, getCustomerPortal, setClientSecret, handleOnChangeNav, refUs, refPrices } = useContext(GlobalContext)
  
  const [ videos, setVideos ] = useState([])
  const [ edu, setEdu ] = useState([])
  const [ potcast, setPotcast ] = useState([])
  const [ showVideo, setShowVideo ] = useState(false);
  const [ showloginMessage, setShowloginMessage ] = useState(false);
  const [ selected, setSelected ] = useState(false);
  const [ activeSubscription, setActiveSubscription ] = useState(null);

  useEffect(() => {
    getContentList()
  }, []);

  useEffect(() => {
    if( sessionStorage.getItem('section') ) {
      var section = sessionStorage.getItem('section')

      var element = eval( 'ref' + section ).current;
      var headerOffset = 86;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      var element = eval( 'ref' + section ).current;
      var headerOffset = 86;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })

      /*eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })*/

      sessionStorage.removeItem( 'section' )
    }

    if( sessionStorage.getItem('userForm') ) {
      setShowPayment(true)
      sessionStorage.removeItem('userForm')
    }
  });

  useEffect(() => {    
    if(stripeCostumer && stripeCostumer.subscriptions.data[0])
      setActiveSubscription( stripeCostumer.subscriptions.data[0].items.data[0].price.id )
  }, [stripeCostumer]);

  const gettingStripeCheckOut = ( price ) => {
    if(logged) {

      if( !activeSubscription ) {
        console.log( 'CheckoutForm uid', customerInfo.uuId )
        var json = {
          items: [ price ],
          mode: 'suscription',
          uid: customerInfo.uuId
        }
        axios({
          method: 'post',
          url: "https://israelnochebuena.com.mx/srv/api/session/create-checkout-session",
          headers: { 'content-type': 'application/json' },
          data: json
        })
        .then(result => {
          console.log("result checkout", result.data)
          setClientSecret(result.data.clientSecret);
          setShowPayment(true)
        })
        .catch(error => {
          console.log("error", error.message)
        })
      } else {
        getCustomerPortal()
      }
    } else {
      setShowloginMessage( true )
    }
  }

  const getContentList = ( updated ) => {
    console.log("getting info", videosRef)
    
    onValue(videosRef, (snapshot) => {
      console.log("service in")
      let genTem = []
      let eduTemp = []
      let potcastTemp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();
        
        if( data.active ){ 
          if( parseInt(data.cat) === 0)
            genTem.push({ "key": keyName, "data": data })
          else if( parseInt(data.cat) === 1)
            eduTemp.push({ "key": keyName, "data": data })
          else if( parseInt(data.cat) === 2)
            potcastTemp.push({ "key": keyName, "data": data })
        }
      })

      setVideos( genTem )
      setEdu( eduTemp )
      setPotcast( potcastTemp )
    }, {
      onlyOnce: true
    }
    );
  }

  const showPlayer = ( item ) => {
    setSelected(item)
    setShowVideo(true)
  }

  const evalSection = () => {
    setShowVideo(false)

    if( !logged )
      window.location.href = '/login'
    else {
      if( customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ) handleOnChangeNav('Prices')
    }
  }

  const handleContextMenu = (e) => {
    console.log('contextEvent', e)
  }

  return (
    <div className='App'>

      <Modal show={ showVideo } onHide={ () => setShowVideo(false) } size="lg" centered>
        <Modal.Header closeButton />
        <Modal.Body>
        
          { (logged && customerInfo && customerInfo.subscriptionId && activeSubscription ) ?
            <div className="player-container">
              <ReactPlayer width="100%" height="100%" onContextMenu={handleContextMenu} url={ selected.url } playing playsinline controls config={{ 
                youtube: {
                  playerVars: { color: 'red', showinfo: 0, fs: 1, controls: 1, modestbranding: 1, playsinline: 0, rel: 0 },
                }
              }} />
              <div className="player-mask-top"> </div>
              <div className="player-mask-bottom"> </div>
            </div> :
            <div className="modal-message">
              <div className="modal-title"> ¡Aviso! </div><br/>
              <div className="modal-desc"> Para poder acceder a este contenido, inicia sesión y/o suscribete a alguno de nuestros planes, en los que podras encontrar muchos más beneficios que tenemos para ti. </div><br/>
              <div>
                <Button className='submit-btn' onClick={() => evalSection() }>
                  { logged && customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ? 'Ver Planes' : 'Iniciar Sesión' }
                </Button>
              </div>
              <div></div>
            </div>
          }
          
        </Modal.Body>
      </Modal>

      <Modal show={ showloginMessage } onHide={ () => setShowloginMessage(false) } centered>
        <Modal.Header closeButton />
        <Modal.Body>
            <div className="modal-message">
              <div className="modal-title"> ¡Aviso! </div><br/>
              <div className="modal-desc"> Inicia sesión o crea una cuenta para poder suscribirte a alguno de nuestros planes donde encontrarás multiples beneficios. </div><br/>
              <div></div>
            </div>          
        </Modal.Body>
      </Modal>
      
      <div style={{ height: '106px' }} />
      <div>
        <Container>
          <Row>
            <Col md={ 6 } className='main-left'>
              <div className='line-info'> 
                <div>
                  <div className='line-title'> 3,000 + </div>
                  <div className='line-subtitle'> Sesiones </div>
                </div>
                <br/>
                <div>
                  <div className='line-title'> 2,000 + </div>
                  <div className='line-subtitle'> Acompañamientos </div>
                </div>
                <br/>
                <div>
                  <div className='line-title'> 80 + </div>
                  <div className='line-subtitle'> Videos </div>
                </div>
              </div>
              <div className='line-card'>
                <div className='line-title line-title-small'> Israel </div>
                <div className='line-title line-title-small'> Nochebuena </div>
                <div className='line-subtitle line-subtitle-small'> Acompañante </div>
              </div>

              <div className='main-container'>
                <div className='separator' />
                <div align='right'> <img className='iw-100' src={ israel } /> </div>
              </div>
            </Col>
            <Col md={ 6 } className='main-right'>
              <Container className='home-style'>
                <Row>
                  <div className='home-title'>
                    “Espero dejar las cosas mejor que como las <b>encontré</b>”
                  </div>
                  <div className='home-subtitle'>
                    Soy Israel Nochebuena y mi misión de vida es ayudar a las personas desde el servicio, es por esto que he gestado este proyecto, para poder brindar a las personas herramientas en donde puedan encontrar salida a conflictos o situaciones que la vida nos pone al frente, muchas veces nos encontramos inmersos dentro de juicios y condicionamientos familiares y sociales que tienen secuestrada nuestra vida y que no nos permiten vivir en plenitud. Esta página es una invitación a que te conozcas internamente y puedas entender el para qué de todas estas dificultades que nos vamos encontrando conforme avanzamos en nuestro camino. ¨Mi religión es vivir y morir sin remordimientos¨.
                  </div>
                </Row>
                <br/><br/>
                <Row>
                  <Col md={ 6 } style={{ marginBottom: "15px" }}>
                    <div style={{ position: 'relative' }}>
                      <div className='home-title-squart' />
                      <div className='home-item-title' >Bioneuroemoción</div>
                      <div className='home-item-subtitle' >Sesiones de Bioneuroemoción individuales y grupales.</div>
                    </div>
                  </Col>
                  <Col md={ 6 } style={{ marginBottom: "15px" }}>
                    <div style={{ position: 'relative' }}>
                      <div className='home-title-squart' />
                      <div className='home-item-title' >Sesiones Grupales</div>
                      <div className='home-item-subtitle' >Sesiones grupales en vivo que tratan diversos temas de desarrollo humano.</div>
                    </div>
                  </Col>
                  <Col md={ 6 } style={{ marginBottom: "15px" }}>
                    <div style={{ position: 'relative' }}>
                      <div className='home-title-squart' />
                      <div className='home-item-title' >Consultorías</div>
                      <div className='home-item-subtitle' >Consultorías individuales y de pareja.</div>
                    </div>
                  </Col>
                  <Col md={ 6 } style={{ marginBottom: "15px" }}>
                    <div style={{ position: 'relative' }}>
                      <div className='home-title-squart' />
                      <div className='home-item-title' >Asesorías personalizadas</div>
                      <div className='home-item-subtitle' >Asesorías personalizadas de proyecto de vida, relaciones de pareja, huella de abandono, entre otros.</div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <div style={{ height: '100px' }} />
        <div ref={ refUs }>
          <Us />
        </div>

        <div style={{ height: '100px' }} />
        <Container>
          <Row>
            <Col>
              <div className="section-title txt-center">
                Videos
              </div>
              <div className="section-desc txt-center"></div>
              <br/>
              <div> 
                <Tabs defaultActiveKey="general" className="mb-3" >
                  <Tab eventKey="general" title="Generales" style={{ padding: '10px 30px'  }}>
                    <Container>
                      <Row align="left">
                        { videos.slice(0, 4).map( ({ data }) => (
                          <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                            <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/general/' + data.id + '.jpg' } /> </div>
                            <div className="list-title">{ data.title }</div>
                            <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                            <div className="list-duration">{ data.duration }</div>
                          </Col>
                        )) }
                      </Row>
                      <Row>
                        <div  align="right">
                          <div style={{ position: 'relative', width: 'fit-content', cursor: 'pointer'}} onClick={ () => window.location.href = '/videos' }>
                            <div className='home-title-squart' />
                            <div className='home-item-title' >Más videos...</div>
                          </div>
                        </div>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="edu" title="Educativos">
                    <Container>
                      <Row align="left">
                        { edu.slice(0, 4).map( ({ data }) => (
                          <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                            <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/edu/' + data.id + '.jpg' } /> </div>
                            <div className="list-title">{ data.title }</div>
                            <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                            <div className="list-duration">{ data.duration }</div>
                          </Col>
                        )) }
                      </Row>
                      <Row>
                        <div  align="right">
                          <div style={{ position: 'relative', width: 'fit-content', cursor: 'pointer'}} onClick={ () => window.location.href = '/videos' }>
                            <div className='home-title-squart' />
                            <div className='home-item-title' >Más videos...</div>
                          </div>
                        </div>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="podcast" title="Podcast">
                    <Container>
                      <Row align="left">
                        { potcast.slice(0, 4).map( ({ data }) => (
                          <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                            <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/podcast/' + data.id + '.jpg' } /> </div>
                            <div className="list-title">{ data.title }</div>
                            <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                            <div className="list-duration">{ data.duration }</div>
                          </Col>
                        )) }
                      </Row>
                      <Row>
                        <div  align="right">
                          <div style={{ position: 'relative', width: 'fit-content', cursor: 'pointer'}} onClick={ () => window.location.href = '/videos' }>
                            <div className='home-title-squart' />
                            <div className='home-item-title' >Más videos...</div>
                          </div>
                        </div>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              </div>
              <div style={{ height: '50px' }} />
            </Col>
          </Row>
          {/* <Row className="align-items-center">
            <Col md={ 6 }>
              { videos.length > 0 && 
                <div className="list-video-main" onClick={ () => showPlayer(videos[0].data) }>
                  <div align="left">
                    <div> <img className="list-preview" src={ videos[0].data.preview } /> </div>
                    <div className="list-title">{ videos[0].data.title }</div>
                    <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date( videos[0].data.date )).getTime()) }</div>
                    <div className="list-duration">{ videos[0].data.duration }</div>
                  </div>
                </div>
              }
            </Col>
            <Col md={ 6 }>
              <Container>
                <Row align="left">
                  { videos.slice(1, 5).map( ({ data }) => (
                    <Col md={ 6 } className="list-video" onClick={ () => showPlayer(data) }>
                      <div> <img className="list-preview" src={ data.preview } /> </div>
                      <div className="list-title">{ data.title }</div>
                      <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                      <div className="list-duration">{ data.duration }</div>
                    </Col>
                  )) }
                </Row>
              </Container>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col align="center">
              <div style={{ position: 'relative', width: 'fit-content', cursor: 'pointer'}} onClick={ () => window.location.href = '/videos' }>
                <div className='home-title-squart' />
                <div className='home-item-title' >Más videos...</div>
              </div>
            </Col>
          </Row> */}
        </Container>

      </div>
      
      <div style={{ height: '50px' }} />
      <div className="section-price" ref={ refPrices }>
        <Container className="section-a">
          <div className="section-title txt-center">
            Precios
          </div>
          <div className="section-desc txt-center">
            Encuentra el plan que más se adapte a tus necesidades. 
          </div>
          <br/>
          <br/>
          <Row>
            <Carousel responsive={responsive} itemClass ="card-container-list" showDots={ true }>
              <div className="price-card">
                <div className={ activeSubscription == 'price_1PYY8bGYJdAolFWkqUhBLs40' ? "card-container card-container-active" : "card-container" } >

                  <div className="card-recomended">&nbsp;</div>
                  <div className="card-title">Básico</div>
                  <div className="card-subtitle">Muestra tu negocio al mundo.</div>
                  <br/>
                  <div align="center">
                    <span className="card-price">$99</span><span className="card-period">/mes<sup>*</sup></span>
                  </div>
                  <br/>
                  <div align="center">
                    <Button className={ activeSubscription == 'price_1PYY8bGYJdAolFWkqUhBLs40' ? 'submit-btn-green' : 'submit-btn' } onClick={() => gettingStripeCheckOut('price_1PYY8bGYJdAolFWkqUhBLs40')}>
                      { activeSubscription == 'price_1PYY8bGYJdAolFWkqUhBLs40' ? 'Suscrito' : 'Suscríbeme' }
                    </Button>
                  </div>
                  <br/>
                  <div className="card-elements">
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Accede a todas las transmisiones en vivo.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a contenidos grabados.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a la comunidad de WhatsApp, donde responderemos tus dudas y podrás interactuar.</span>
                    </div>
                  </div>

                </div>
              </div>
              
              <div className="price-card">

                <div className={ activeSubscription == 'price_1PYY8ZGYJdAolFWky6KRl1Wk' ? "card-container card-container-active" : "card-container" }>
                  <div className="card-recomended">PROMOCIÓN</div>
                  <div className="card-title">Medio</div>
                  <div className="card-subtitle">Que el mundo te descubra.</div>
                  <br/>
                  <div align="center">
                    <span className="card-price">$300</span><span className="card-period">/mes<sup>*</sup></span>
                  </div>
                  <br/>
                  <div align="center">
                    <Button className={ activeSubscription == 'price_1PYY8ZGYJdAolFWky6KRl1Wk' ? 'submit-btn-green' : 'submit-btn' } onClick={() => gettingStripeCheckOut('price_1PYY8ZGYJdAolFWky6KRl1Wk')}>
                      { activeSubscription == 'price_1PYY8ZGYJdAolFWky6KRl1Wk' ? 'Suscrito' : 'Suscríbeme' }
                    </Button>
                  </div>
                  <br/>
                  <div className="card-elements">
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Accede a todas las transmisiones en vivo.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a contenidos grabados.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a la comunidad de WhatsApp, donde responderemos tus dudas y podrás interactuar.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a acompañamientos grupales.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a una meditación por mes.</span>
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="price-card">

                <div className={ activeSubscription == 'price_1PYY8XGYJdAolFWkAOihcM01' ? "card-container card-container-active" : "card-container" }>
                  <div className="card-recomended">&nbsp;</div>
                  <div className="card-title">Avanzado</div>
                  <div className="card-subtitle">Demuéstrale al mundo lo genial de tus productos.</div>
                  <br/>
                  <div align="center">
                    <span className="card-price">$1,000</span><span className="card-period">/mes<sup>*</sup></span>
                  </div>
                  <br/>
                  <div align="center">
                    <Button className={ activeSubscription == 'price_1PYY8XGYJdAolFWkAOihcM01' ? 'submit-btn-green' : 'submit-btn' } onClick={() => gettingStripeCheckOut('price_1PYY8XGYJdAolFWkAOihcM01')}>
                      { activeSubscription == 'price_1PYY8XGYJdAolFWkAOihcM01' ? 'Suscrito' : 'Suscríbeme' }
                    </Button>
                  </div>
                  <br/>
                  <div className="card-elements">
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Accede a todas las transmisiones en vivo.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a todos los acompañamientos grupales.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a consulta grupal como paciente.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a contenidos grabados.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a la comunidad de WhatsApp, donde responderemos tus dudas y podrás interactuar.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a 4 meditaciones por mes.</span>
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="price-card">

                <div className={ activeSubscription == 'price_1PYY8UGYJdAolFWkBPlPKBTQ' ? "card-container card-container-active" : "card-container" }>
                  <div className="card-recomended">&nbsp;</div>
                  <div className="card-title">Premium</div>
                  <div className="card-subtitle">Demuéstrale al mundo lo genial de tus productos.</div>
                  <br/>
                  <div align="center">
                    <span className="card-price">$2,500</span><span className="card-period">/mes<sup>*</sup></span>
                  </div>
                  <br/>
                  <div align="center">
                    <Button className={ activeSubscription == 'price_1PYY8UGYJdAolFWkBPlPKBTQ' ? 'submit-btn-green' : 'submit-btn' } onClick={() => gettingStripeCheckOut('price_1PYY8UGYJdAolFWkBPlPKBTQ')}>
                      { activeSubscription == 'price_1PYY8UGYJdAolFWkBPlPKBTQ' ? 'Suscrito' : 'Suscríbeme' }
                    </Button>
                  </div>
                  <br/>
                  <div className="card-elements">
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Accede a todas las transmisiones en vivo.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a todos los acompañamientos grupales.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a consulta grupal como paciente.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a consulta individual como paciente.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a contenidos grabados.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a la comunidad de WhatsApp, donde responderemos tus dudas y podrás interactuar.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a 4 meditaciones por mes.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Descuentos especiales en talleres presenciales y virtuales.</span>
                    </div>
                    <div className="p-2">
                      <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                      <span>Acceso a reuniones presenciales - charlas con café. </span>
                    </div>
                  </div>
                </div>

              </div>
            </Carousel>

            {/* <Col md={ 4 } className="col-promos">
              <div className="card-container">
                <div className="card-recomended">&nbsp;</div>
                <div className="card-title">Básico</div>
                <div className="card-subtitle">Muestra tu negocio al mundo.</div>
                <br/>
                <div>
                  <span className="card-price">$99</span><span className="card-period">/mes<sup>*</sup></span>
                </div>
                <br/>
                <div className="card-elements">
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>3 en vivo al mes sin límite de tiempo sin acceso a preguntas y respuestas.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Acceso a contenidos grabados.</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={ 4 } className="col-promos">
              <div className="card-container card-container-recommended">
                <div className="card-recomended">PROMOCIÓN</div>
                <div className="card-title">Medio</div>
                <div className="card-subtitle">Que el mundo te descubra.</div>
                <br/>
                <div>
                  <span className="card-price">$300</span><span className="card-period">/mes<sup>*</sup></span>
                </div>
                <br/>
                <div className="card-elements">
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>3 en vivo al mes sin límite de tiempo con acceso a preguntas y respuestas (6 meses de prueba).</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>1 Acompañamientos pueden verlo pero no ser paciente.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Meditaciones (1 por mes).</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Acceso a contenidos grabados.</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={ 4 } className="col-promos">
              <div className="card-container">
                <div className="card-recomended">&nbsp;</div>
                <div className="card-title">Avanzado</div>
                <div className="card-subtitle">Demuéstrale al mundo lo genial de tus productos.</div>
                <br/>
                <div>
                  <span className="card-price">$1,000</span><span className="card-period">/mes<sup>*</sup></span>
                </div>
                <br/>
                <div className="card-elements">
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>3 en vivo al mes sin límite de tiempo con acceso a preguntas y respuestas.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Acceso a todos los acompañamiento grupal pueden verlo pero no ser paciente (En caso de no querer ser acompañado puede cederlo a alguien más).</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Sorteado con base en su desempeño en el grupo.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Acceso a contenidos grabados.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Meditaciones (4 por mes).</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={ 4 } className="col-promos">
              <div className="card-container">
                <div className="card-recomended">&nbsp;</div>
                <div className="card-title">Premium</div>
                <div className="card-subtitle">Demuéstrale al mundo lo genial de tus productos.</div>
                <br/>
                <div>
                  <span className="card-price">$2,500</span><span className="card-period">/mes<sup>*</sup></span>
                </div>
                <br/>
                <div className="card-elements">
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>3 en vivo al mes sin límite de tiempo con acceso a preguntas y respuestas.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Todos los acompañamientos grupal pueden ser paciente  (En caso de no querer ser acompañado puede cederlo a alguien más).</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>1 acompañamiento individual por mes en privado vía zoom o presencia.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Acceso a contenidos grabados.</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Meditaciones (4 por mes).</span>
                  </div>
                  <div className="p-2">
                    <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                    <span>Asistencia a ciertos talleres con costos preferenciales.</span>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Main;
