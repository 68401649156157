import React, { useState, useEffect, useContext } from "react"
import { database } from "./firebaseConfig"
import { ref, onValue } from "firebase/database"
import ReactPlayer from 'react-player'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Button from 'react-bootstrap/Button'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-multi-carousel/lib/styles.css"

import GlobalContext from "./context/GlobalContext";

const videosRef = ref(database, 'videos/')
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

function Videos() {
  const { logged, customerInfo, stripeCostumer, handleOnChangeNav } = useContext(GlobalContext)
  
  const [ videos, setVideos ] = useState([])
  const [ edu, setEdu ] = useState([])
  const [ potcast, setPotcast ] = useState([])
  const [ showVideo, setShowVideo ] = useState(false)
  const [ selected, setSelected ] = useState(false)
  const [ activeSubscription, setActiveSubscription ] = useState(null);

  useEffect(() => {
    getContentList()
  }, []);

  useEffect(() => {    
    if(stripeCostumer && stripeCostumer.subscriptions.data[0])
      setActiveSubscription( stripeCostumer.subscriptions.data[0].items.data[0].price.id )
  }, [stripeCostumer]);

  const getContentList = ( updated ) => {
    console.log("getting info", videosRef)
    
    onValue(videosRef, (snapshot) => {
      console.log("service in")
      let genTem = []
      let eduTemp = []
      let potcastTemp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();

        if( data.active ){ 
          if( parseInt(data.cat) === 0)
            genTem.push({ "key": keyName, "data": data })
          else if( parseInt(data.cat) === 1)
            eduTemp.push({ "key": keyName, "data": data })
          else if( parseInt(data.cat) === 2)
            potcastTemp.push({ "key": keyName, "data": data })
        }
      })

      setVideos( genTem )
      setEdu( eduTemp )
      setPotcast( potcastTemp )
    }, {
      onlyOnce: true
    }
    );
  }

  const showPlayer = ( item ) => {
    setSelected(item)
    setShowVideo(true)
  }

  const evalSection = () => {
    setShowVideo(false)

    if( !logged )
      window.location.href = '/login'
    else {
      if( customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ) handleOnChangeNav('Prices')
    }
  }

  return (
    <div className='App'>

      <Modal show={ showVideo } onHide={ () => setShowVideo(false) } size="lg" centered>
        <Modal.Header closeButton />
        <Modal.Body>
          {/*<img src="https://drive.google.com/thumbnail?id=1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG&sz=w1000" alt=""/>
          <iframe src="https://drive.google.com/file/d/1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG/preview" width="640" height="480" allow="autoplay" frameborder="0" scrolling="no" seamless=""></iframe>*/}

          {/*<div style={{ width: "640px", height: "480px", position: "relative" }}>
            <iframe src="https://drive.google.com/file/d/1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG/preview"   width="640" height="480" frameborder="0" scrolling="no" seamless=""></iframe>
            <div style={{ width: "80px", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px" }}> </div>
          </div>*/}

          { (logged && customerInfo && customerInfo.subscriptionId && activeSubscription ) ?
            <div onContextMenu={(e) => e.preventDefault()} className="player-container">
              {/* <iframe src={ 'https://drive.google.com/file/d/' + selected.id + '/preview' }   width="640" height="480" frameborder="0" scrolling="no" seamless=""></iframe>
              <div style={{ width: "80px", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px" }}> </div> */}
              {/*<ReactPlayer url={ 'https://www.googleapis.com/drive/v3/files/' + selected.id + '?alt=media&key=AIzaSyCE3v9IXoxyfiFN1vVmH35-vJ0lxUF-YI8' } playing playsinline controls config={{ file: { 
                attributes: {
                  controlsList: 'nodownload'
                }
              }}} />*/}

              <ReactPlayer width="100%" height="100%" url={ selected.url } playing playsinline controls config={{ 
                youtube: {
                  playerVars: { color: 'red', showinfo: 0, fs: 1, controls: 1, modestbranding: 1, playsinline: 0, rel: 0 },
                }
              }} />
              <div className="player-mask-top"> </div>
              <div className="player-mask-bottom"> </div>
            </div> :
            <div className="modal-message">
              <div className="modal-title"> ¡Aviso! </div><br/>
              <div className="modal-desc"> Para poder acceder a este contenido, inicia sesión y/o suscribete a alguno de nuestros planes, en los que podras encontrar muchos más beneficios que tenemos para ti. </div><br/>
              <div>
                <Button className='submit-btn' onClick={() => evalSection() }>
                  { logged && customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ? 'Ver Planes' : 'Iniciar Sesión' }
                </Button>
              </div>
              <div></div>
            </div>
          }
        </Modal.Body>
      </Modal>
      
      <div style={{ height: '106px' }} />
      <div>
        <Container>
          <Row>
            <Col>
              <div className="section-title txt-center">
                Videos
              </div>
              <div className="section-desc txt-center"></div>
              {/* <div style={{ height: '50px' }} /> */}
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 12 }>
              <Tabs defaultActiveKey="general" className="mb-3" >
                <Tab eventKey="general" title="Generales" style={{ padding: '10px 30px'  }}>
                  <Container>
                    <Row>
                      <Col>
                      <div className='home-subtitle'> En esta serie de videos abordamos temas cotidianos que hoy en día están muy vigentes y son de interés común, que te permitirán tener una visión y perspectiva incluso en momentos desafiantes, que se convertirán en una herramienta más para la transformación de tu autoconcepto y para anular la emisión de juicios en pro de tu crecimiento como persona. </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row align="left">
                    { videos.map( ({ data }) => (
                        <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                          <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/general/' + data.id + '.jpg' } /> </div>
                          <div className="list-title">{ data.title }</div>
                          <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                          <div className="list-duration">{ data.duration }</div>
                        </Col>
                    )) }
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="edu" title="Educativos" style={{ padding: '10px 30px'  }}>
                  <Container>
                    <Row>
                      <Col>
                      <div className='home-subtitle'> Esta serie de videos te permitirá profundizar en tu autoconocimiento y está diseñada de una manera terapéutica y educativa, por lo tanto tienen un orden y una secuencia cuya finalidad es ponerte al centro y reestructurar tu proyecto de vida, guiándote por un camino de transformación a ese tan anhelado proyecto que todos tenemos: la individualización personal, para alcanzar nuestra máxima plenitud como seres humanos. </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row align="left">
                      { edu.map( ({ data }) => (
                        <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                          <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/edu/' + data.id + '.jpg' } /> </div>
                          <div className="list-title">{ data.title }</div>
                          <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                          <div className="list-duration">{ data.duration }</div>
                        </Col>
                      )) }
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="podcast" title="Podcast" style={{ padding: '10px 30px'  }}>
                  <Container>
                    <Row>
                      <Col>
                      <div className='home-subtitle'> Una de las grandes paradojas en nuestra vida es que nos la tomamos muy en serio, sin darnos cuenta que cuando tenemos dificultades nos subordinamos y nos esclavizamos al sufrimiento y al mismo tiempo también queremos vivir con alegría; es por esto que a través de esta serie de videos tocaremos temas cruciales de nuestras vidas, que aunque suenen contradictorios, los abordaremos de una manera irreverente, sarcástica y divertida, como una invitación a vivir en el sendero del medio, tocando temas trascendentes con la máxima alegría y sin perder la perspectiva de su importancia. </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row align="left">
                      { potcast.map( ({ data }) => (
                        <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                          <div> <img className="list-preview" src={ 'https://israelnochebuena.com.mx/preview/podcast/' + data.id + '.jpg' } /> </div>
                          <div className="list-title">{ data.title }</div>
                          <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                          <div className="list-duration">{ data.duration }</div>
                        </Col>
                      )) }
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>

      </div>
      <br/><br/><br/>
    </div>
  );
}

export default Videos;
