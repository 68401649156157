import { useState, useEffect, useContext } from 'react'
import { getAuth, updatePassword, createUserWithEmailAndPassword, EmailAuthProvider, sendEmailVerification } from 'firebase/auth'
import { auth } from "./firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
//import GoogleButton from 'react-google-button'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'

import { CheckCircleFill, ExclamationCircleFill, EyeFill, EyeSlashFill, Facebook, Google } from 'react-bootstrap-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import profile from './img/profile.jpg'
import logo from './img/logo.png'

import GlobalContext from "./context/GlobalContext"

function ClientForm() {
  const { getLogin, uId } = useContext(GlobalContext);

  const CREATE_CUSTOMER_API = 'https://israelnochebuena.com.mx/srv/api/session/customers/'
  const GET_CUSTOMER_API = 'https://israelnochebuena.com.mx/srv/api/session/customers/' + uId

  const [client, setClient] = useState(null)
  const [loanding, setLoanding] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [showForm, setShowForm] = useState(true)

  const [showMessage, setShowMessage] = useState(false)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")

  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showNewPass, setShowNewPass] = useState("")
  const [showingPass, setShowingPass] = useState(false)
  const [showingConfirmPass, setShowingConfirmPass] = useState(false)

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);

  const [emailVerified, setEmailVerified] = useState(false);

  const [saved, setSaved] = useState(false)
  const [authCustomer, setAuthCustomer] = useState("")

  const handleChange = file => {
    setFile(file);
    setFileName((file.name).replace(/ /g,""))
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgSrc([reader.result]);
    }.bind(this);
    console.log(url) // Would see a path?
  };

  useEffect(() => {
    console.log("register user:", uId, GET_CUSTOMER_API, client, showForm)
    if(uId){
      console.log("check user")
      setLoanding(true)
      const fireUser = auth.currentUser

      if(fireUser)
        setEmailVerified(fireUser.emailVerified);

      axios
      .get(GET_CUSTOMER_API + "?rand=" + Math.random(), {}, {})
      .then(function(result) {
        var res = result.data
        
        setPhone(res.phone)
        setEmail(res.email)
        setName(res.name)
        setClient(res)

        setAuthCustomer(sessionStorage.getItem("emt_auth"))
        setLoanding(false)
        setShowForm(true)
      })
      .catch(function(err) {
        setLoanding(false)
      })
    }
  }, []);

  const sendVerificacionEmail = () => {
    setLoanding(true)
    const fireUser = auth.currentUser

    sendEmailVerification(fireUser);
    setTitle("¡Correo enviado!")
    setMessage("Pronto recibirás un correo con las instrucciones para vefiricar tu email.")
    setShowMessage(true)
    setLoanding(false)
  }

  const confirmChangePassword = (e) => {

    if( newPassword != confirmNewPassword ) {
      setTitle("Advertencia")
      setMessage("Las contraseña son distintas, favor de verificarlas.")
      setShowMessage(true)
    } else if(!evalPass(newPassword)){
      setTitle("Advertencia")
      setMessage("La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y ser mayor o igual a 8 caracteres.")
      setShowMessage(true)
    } else {
      const fireUser = auth.currentUser
      setIsActive(true)

      updatePassword(fireUser, newPassword).then(function() {
        setTitle("¡Cambio de contraseña exitoso!")
        setMessage("¡Tu contraseña ha sido cambiada con éxito!")
        setSaved(true)
        setShowMessage(true)
        setIsActive(false)
      }).catch(function(error) {
        setTitle("Error")
        setMessage("Ocurrió un error, favor de intentarlo mas tarde.")
        setShowMessage(true)
        setIsActive(false)
      });
    }
  }

  const updateCustomer = (e) => {
    e.preventDefault();
    setLoanding(true)

    var json = {
      'uuId': client.uuId,
      'name': name,
      'email': email,
      'phone': phone,
      'createdtime': client.createdTime,
      'plan': client.plan,
      'sessionId': client.sessionId,
      'subscriptionId': client.subscriptionId,
      'active': true
    }
    
    axios
      .post(CREATE_CUSTOMER_API, json)
      .then((res) => {
            setTitle("¡Registro guardado exitosamente!")
            setMessage("")
            setSaved(true)
            setClient(res.data)
            setShowMessage(true)
            setLoanding(false)

            var authInfo = sessionStorage.getItem("td_auth_info").split(":")[0] + ":"
              + sessionStorage.getItem("td_auth_info").split(":")[1] + ":"
              + sessionStorage.getItem("td_auth_info").split(":")[2]

            sessionStorage.setItem("td_auth_info", authInfo)
      })
      .catch((err) => {
        setTitle("Error")
        setMessage("Ocurrió un error, favor de intentarlo mas tarde.")
        setShowMessage(true)
        setLoanding(false)
      });
    
  }

  const evalPass = (pass) => {
    var upperCaseLetters = /[A-Z]/g;
    var lowerCaseLetters = /[a-z]/g;
    var numbers = /[0-9]/g;

    if(pass.length > 8 && pass.match(lowerCaseLetters) && pass.match(upperCaseLetters) && pass.match(numbers)) return true
    else return false
  }

  const createCustomer = (e) => {
    e.preventDefault();
    setLoanding(true)
    var uuid = uuidv4()

    if(evalPass(userPassword)){

      if(userPassword === userConfirmPassword) {
        createUserWithEmailAndPassword(auth, email, userPassword)
          .then((userCredential) => {
            console.log( 'userCredential', userCredential )
            sendEmailVerification(userCredential.user);

            var json = {
              'uuId': userCredential.user.uid,
              'name': name,
              'email': email,
              'phone': phone,
              'createdTime': new Date(),
              'plan': null,
              'sessionId': null,
              'subscriptionId': null,
              'active': true
            }

            axios({
              method: 'post',
              url: CREATE_CUSTOMER_API,
              headers: { 'content-type': 'application/json' },
              data: json
            })
            //.put(CREATE_CUSTOMER_API, json)
            .then((res) => {
              console.log("user created", res.data)

              setTitle("¡Registro Exitoso!")
              setMessage("¡El registro ha sido guardado con éxito!")
              setSaved(true)
              setShowMessage(true)
              setLoanding(false)
              
            })
            .catch((err) => {
              setTitle("Error")
              setMessage("Ocurrió un error, favor de intentarlo mas tarde.")
              setShowMessage(true)
              setLoanding(false)
            });
        })
      } else {
        setTitle("Advertencia")
        setMessage("La contraseña y la confirmación de la contraseña deben ser iguales.")
        setShowMessage(true)
      }
    } else {
      setTitle("Advertencia")
      setMessage("La contraseña debe contener al menos una letra mayúscula, una minúscula, un número y ser mayor o igual a 8 caracteres.")
      setShowMessage(true)
    }
    
  }

  const handleCloseMessage = () => {
    if(saved) window.location.reload();
    setShowMessage(false)
    setLoanding(false)
  }

  return (
    <div>
      <Container className="card-container" style={{ border: "0" }}>
        <Container>
          <Row>
            <Col>
              <div className="title-bar" align="center" style={{ color: "#000" }}>
                { client ? 
                  <div>
                    <div align="center"> <img style={{ width: '30%' }} src={ profile } /> </div>
                    <br/>
                    <div className="home-item-title" style={{ textAlign: 'center' }}>Actualiza tu REGISTRO</div>
                  </div> : 
                  <div></div> 
                }
                { !client && showForm ? 
                  <div>
                    <div align="center"> <img style={{ width: '40%' }} src={ logo } /> </div>
                    <br/>
                    <div className="home-item-title" style={{ textAlign: 'center' }}>Captura tus datos para comenzar tu REGISTRO</div>
                  </div> : 
                  <div></div> 
                }
                </div>
                <br/>

              { showForm && 
              <Form onSubmit={client ? updateCustomer : createCustomer} style={{ padding: "0 25px" }} >

                <Form.Group as={Col}>
                  {/*<Form.Label>Nombre</Form.Label>*/}
                  <Form.Control required type="text" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)}/>
                  <br/>
                </Form.Group> 

                <Form.Group as={Col}>
                  {/*<Form.Label>Email</Form.Label>*/}
                  <Form.Control required type="email" disabled={client} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                  { (client && !emailVerified) && 
                    <Form.Text className="text-muted">
                      Tu email aun no esta verificado, por favor da click <span onClick={sendVerificacionEmail} className="verified-email">aqui</span> para recibir un email con las instrucciones de verificación.
                      <br/>
                    </Form.Text>
                  }
                  
                  <br/>
                </Form.Group>

                <Form.Group as={Col}>
                  {/*<Form.Label>Teléfono</Form.Label>*/}
                  <Form.Control type="text" placeholder="Teléfono" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                  <br/>
                </Form.Group>

                {!client &&
                  <div>
                    <Form.Group as={Col}>
                        {/*<Form.Label>Password</Form.Label>*/}
                      <InputGroup>
                        <Form.Control type={ showingPass ? "text" : "password" } placeholder="Contraseña" value={userPassword} onChange={(e) => setUserPassword(e.target.value)}/>
                        <InputGroup.Text id="inputGroup-sizing-default" className="password-style" onClick={ () => setShowingPass( showingPass ? false : true ) } >{ showingPass ? <EyeSlashFill /> : <EyeFill/> } </InputGroup.Text>
                      </InputGroup>
                      <br/>
                    </Form.Group>
                    

                    <Form.Group as={Col}>
                      {/*<Form.Label>Password</Form.Label>*/}
                      <InputGroup>
                        <Form.Control type={ showingConfirmPass ? "text" : "password" } placeholder="Confirmar contraseña" value={userConfirmPassword} onChange={(e) => setUserConfirmPassword(e.target.value)}/>
                        <InputGroup.Text id="inputGroup-sizing-default" className="password-style" onClick={ () => setShowingConfirmPass( showingConfirmPass ? false : true ) } >{ showingConfirmPass ? <EyeSlashFill /> : <EyeFill/> } </InputGroup.Text>
                      </InputGroup>
                      <br/>
                    </Form.Group>
                  </div>

                }

                {(client && authCustomer == 'normal') && 
                  <div>
                    <Form.Check 
                      type="switch"
                      id="custom-switch"
                      label="Cambiar Contraseña"
                      value={showNewPass}
                      onChange={ (e) => setShowNewPass(e.target.checked) }
                    />
                    <br/>
                  </div>
                }

                { showNewPass && 
                  <div>
                    <Form.Group as={Col}>
                      {/*<Form.Label>Password</Form.Label>*/}
                      <Form.Control type="password" placeholder="nueva contraseña" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                      <br/>
                      <Form.Control type="password" placeholder="Confirmar nueva contraseña" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                      <br/>
                    </Form.Group>
                    <div align="right">
                      <Button variant="warning" disabled={isActive} onClick={ () => confirmChangePassword() }>
                        <Spinner
                          className={isActive ? '' : 'hide'}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        { isActive ? "Cambiando..." : "Cambiar contraseña" }
                      </Button>
                    </div>
                    <br/>
                  </div>
                }

                <div align="center">
                  
                  <Button className='submit-btn' type="submit" disabled={showNewPass ? true : false}>
                    {client ? "Actualizar" : "Unirme" }
                  </Button>
                </div>
                <br/>
              </Form>
              }
              {/*!client && 
                  <div style={{ padding: "0 25px" }} >
                    <Container>
                      <Row>
                        <br/>
                          <div align="center"> ó </div>
                        <br/>
                        <br/>
                      </Row>
                      <Row>
                      <Col xs={6} md={6} align="right">
                        <Facebook onClick={ () => getLogin('', '', 'facebook') } style={{ fontSize: "20px" }}/> &nbsp;<span className="btn-login-social"></span>
                      </Col>
                      <Col xs={6} md={6} align="left">
                        <Google onClick={ () => getLogin('', '', 'google') } style={{ fontSize: "20px" }}/> &nbsp;<span className="btn-login-social"></span>
                      </Col>
                      </Row>
                    </Container>
                  </div>
              */}
            </Col>
          </Row>
        </Container>
      </Container>

      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header closeButton />
        <Modal.Body align="center">
          <div className='modal-message'>
            {(title == "Error" || title == "Advertencia") && <ExclamationCircleFill className="modal-icon-error"/> }
            {(title != "Error" && title != "Advertencia") && <CheckCircleFill className="modal-icon-ok"/> }
            <div className="modal-title">{ title }</div>
            <br/>
            <div className="modal-desc"> { message } </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-style">
          <Button variant="warning" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClientForm;
