//import React, { useState, useEffect, useRef, useReducer, useMemo } from "react";
import React, { useState, useEffect, useRef } from "react";
import GlobalContext from "./GlobalContext";
import { auth } from "../firebaseConfig";
import { signOut, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, FacebookAuthProvider, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth'
import axios from 'axios'

const faceProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();

const GET_CUSTOMER_API = 'https://israelnochebuena.com.mx/srv/api/session/customers/{uuid}'
const GET_CUSTOMER_PORTAL_API = 'https://israelnochebuena.com.mx/srv/api/session/create-customer-portal-session/{customerId}'
const CREATE_CUSTOMER_API = 'https://israelnochebuena.com.mx/srv/api/session/customers/'
const navPath = [ 'Us', 'Prices' ]

export default function ContextWrapper(props) {
  const [ logged, setLogged] = useState(false)
  const [ emailVerified, setEmailVerified] = useState(false)
  const [ client, setClient] = useState(null)
  const [ uId, setUId] = useState(null)
  const [ customerId, setCustomerId ] = useState(null)
  const [ customerInfo, setCustomerInfo ] = useState(null)
  const [ showSubscription, setShowSubscription ] = useState(null)
  const [ wrongCrdentials, setWrongCrdentials ] = useState(false)
  const [ tooManyAttemps, setTooManyAttemps ] = useState(false)
  const [ passReset, setPassReset ] = useState(false)
  const [ searchText, setSearchText ] = useState('Enviar')
  const [ btnDisabled, setBtnDisabled ] = useState(false)
  const [ showForgoten, setShowForgoten ] = useState(false)
  const [ showPayment, setShowPayment ] = useState(false)
  const [ stripeCostumer, setStripeCostumer ] = useState(null)
  const [ clientSecret, setClientSecret ] = useState(null)

  const [ path, ] = useState( window.location.pathname.replace(/\//g, '') )

  const refUs = useRef()
  const refPrices = useRef()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUId(uid)
        setLogged(true)
        setEmailVerified(user.emailVerified)
        setClient(user)
        getUserInfo(user)
      }
    });
  }, [])

  const logOut = () => {
    signOut(auth).then(() => {
      window.location.reload()
    }).catch((error) => {
      // An error happened.
    });
  }

  const getLogin = (userName, password, provider) => {
    setSearchText('Enviando...')
    setBtnDisabled( true )

    if(provider == "normal") {
      signInWithEmailAndPassword(auth, userName, password)
      .then(result => {
        console.log("normal login", result);

        getUserInfo(result.user, provider)

        setSearchText('Enviar')
        setBtnDisabled( false )
      })
      .catch(error => {
        console.log("error", error.message)

        if( (error.message).includes('auth/too-many-requests')) setTooManyAttemps( true )
        else if( (error.message).includes('(auth/user-not-found)') || (error.message).includes('(auth/wrong-password)')) setWrongCrdentials( true )

        setSearchText('Enviar')
        setBtnDisabled( false )
      });
    } else {
      var selectedProvider = provider == 'google' ? googleProvider : faceProvider

      signInWithPopup(auth, selectedProvider)
      .then(result => {
        console.log(result);
        const credential = provider == 'google' ? GoogleAuthProvider.credentialFromResult(result) : FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log("google", result, credential, accessToken)
        
        setEmailVerified(result.user.emailVerified)
        getUserInfo(result.user, provider)
        console.log("faceValue", result.user)

        //getUserInfo(result.user.displayName, result.user.photoURL, result, provider)
        setSearchText('Enviar')
        setBtnDisabled( false )
      })
      .catch(error => {
        setWrongCrdentials( true )
        setSearchText('Enviar')
        setBtnDisabled( false )
      });
    }
  }

  const getUserInfo = (user, provider) => {
    axios
    .get(GET_CUSTOMER_API.replace('{uuid}', user.uid) + "?rand=" + Math.random(), {}, {})
    .then(function(result) {
      setLogged(true)

      console.log( 'customer info', result.data, JSON.parse(result.data.stripeCustomer) )
      var sCustomer = JSON.parse(result.data.stripeCustomer)
      var rest = result.data
      rest.stripeCustomer = null
      
      setCustomerId( sCustomer.id )
      setCustomerInfo( result.data )
      setStripeCostumer( sCustomer )

      if( sCustomer.subscriptions == null )
        setWrongCrdentials( true )
    })
    .catch(function(err) {
    })

  }
  
  const getCustomerPortal = () => {
    console.log(" Customer Portal ", customerId)
    axios
    .get(GET_CUSTOMER_PORTAL_API.replace('{customerId}', customerId) + "?rand=" + Math.random(), {}, {})
    .then(function(result) {
      setLogged(true)

      console.log( 'customer portal info', result.data )
      window.location.href = result.data.url
    })
    .catch(function(err) {
    })

  } 

  const forgotPassword = (e) => {
    //e.preventDefault()

    sendPasswordResetEmail(auth, e)
    .then(function () {
      console.log()
      setShowForgoten(false);
      setPassReset(true)
    }).catch(function (e) {
      setPassReset(false)
    })
  }

  const handleOnChangeNav = ( section ) => {
    if( navPath.includes( section ) && path != '' && path != '/' ) {
      sessionStorage.setItem('section', section);
      window.location.href = '/'
    } else {
      //console.log("go to section", section)
    
      var element = eval( 'ref' + section ).current;
      var headerOffset = 86;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    
      /*eval(section + 'Ref').current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })*/
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        uId, setUId,
        logged, setLogged,
        emailVerified, setEmailVerified,
        client, setClient,
        customerId, setCustomerId,
        customerInfo, setCustomerInfo,
        showSubscription, setShowSubscription,
        wrongCrdentials, setWrongCrdentials,
        tooManyAttemps, setTooManyAttemps,
        passReset, setPassReset,
        searchText, setSearchText,
        btnDisabled, setBtnDisabled,
        showForgoten, setShowForgoten,
        logOut,
        getLogin,
        getUserInfo,
        forgotPassword,
        getCustomerPortal,
        showPayment, setShowPayment,
        stripeCostumer, setStripeCostumer,
        clientSecret, setClientSecret,
        handleOnChangeNav,
        refUs, refPrices
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
