import { useState, useEffect, useContext } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './App.css'

import GlobalContext from "./context/GlobalContext"

var url_string = window.location.href
var url = new URL(url_string)
var pid = url.searchParams.get("pid")

function Live() {
  const { logged, stripeCostumer, customerInfo, handleOnChangeNav } = useContext(GlobalContext);
  const [ activeSubscription, setActiveSubscription ] = useState(null);

  useEffect(() => {    
    if(stripeCostumer && stripeCostumer.subscriptions.data[0])
      setActiveSubscription( stripeCostumer.subscriptions.data[0].items.data[0].price.id )
  }, [stripeCostumer]);

  const evalSection = () => {
    if( !logged )
      window.location.href = '/login'
    else {
      if( customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ) handleOnChangeNav('Prices')
    }
  }

  return (
    <div className="">
      <div style={{ height: '106px' }} />
      { (logged && activeSubscription) ?
        <div align="center ">
          <Container>
            <Row>
              <Col md={ 9 }>
                <div style={{ position: 'relative' }}>
                  <iframe width="100%" height="809" src="https://www.youtube.com/embed/Zi3YQnbigbE" title="Transmision de prueba" frameborder="0" allow="accelerometer; autoplay;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <div style={{ width: "100%", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px" }}> </div>
                  <div style={{ width: "100%", height: "80px", position: "absolute", opacity: "0", left: "0px", bottom: "0px" }}> </div>
                </div>
              </Col>
              <Col md={ 3 }>
                <iframe width="100%" height="360" src="https://www.youtube.com/live_chat?v=Zi3YQnbigbE&embed_domain=israelnochebuena.com.mx"></iframe>
                {/* activeSubscription != 'price_1PYY8bGYJdAolFWkqUhBLs40' ? 
                  <iframe width="100%" height="360" src="https://www.youtube.com/live_chat?v=Zi3YQnbigbE&embed_domain=israelnochebuena.com.mx"></iframe>
                  : 
                  <div className='chat-text'> <br/> El Chat esta desactivado. Para tener acceso a él, actualiza tu plan de suscripción y beneficiate de mas servicios que tenemos para ti. </div>
                */}
              </Col>
            </Row>
          </Container>
          <br/><br/>
        </div> :
        <Container align="center">
          <div className="section-title txt-center">
            Solo falta un paso más...
          </div>
          <div className="modal-desc" style={{ fontSize: '18px', padding: '10px 20px'  }}> Para poder acceder a este contenido, inicia sesión y/o suscribete a alguno de nuestros planes, en los que podras encontrar muchos más beneficios que tenemos para ti. </div><br/>
          <div>
            <Button className='submit-btn' onClick={() => evalSection() }>
              { logged && customerInfo && (!customerInfo.subscriptionId || !activeSubscription) ? 'Ver Planes' : 'Iniciar Sesión' }
            </Button>
          </div>
          <br/><br/><br/>
        </Container>
      }

    </div>
  );
}

export default Live;
