import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./Login"
import Main from './Main'
import Live from './Live'
import Videos from './Videos'
import Header from './Header'
import Footer from "./Footer"

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

function App() {
  
  useEffect(() => {    
    const sessionId = urlParams.get('session_id')

    if( sessionId ) window.location.href = "/"
  }, []);

  return (
    <div>
      
      { !window.location.pathname.includes('/login') && 
        <Header />
      }
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="login" element={<Login />} />
          <Route path="onlive" element={<Live />} />
          <Route path="videos" element={<Videos />} />
        </Routes>
      </BrowserRouter>
      
      <Footer />
      
    </div>
  );
}

export default App;
